<template>
  <v-card
    id="reservation-form"
    min-height="350"
  >
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <recurring-reservation-tab-reservations
          :data-params="reservationData"
          :tab="tab"
          :option="option"
          :types="typesOptions"
          @changeTab="changeTab"
          @save="onSave"
        ></recurring-reservation-tab-reservations>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
  mdiCalendarCheckOutline,
} from '@mdi/js'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import useSelectOptions from '@/@core/utils/useSelectOptions'
import useCryptoJs from '@core/utils/useCryptoJs'
import Swal from 'sweetalert2'
import {
  createRecurringReservation,
  updateRecurringEndDate,
} from '@api'

// demos
// import ReservationTabGeneral from './ReservationTabGeneral.vue'
import RecurringReservationTabReservations from './RecurringReservationTabReservations.vue'

export default {
  components: {
    // ReservationTabGeneral,
    RecurringReservationTabReservations,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { userData } = useCryptoJs()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions(true)

    const tab = ref(0)
    const option = ref(0)
    const reservationId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const reservationData = ref({
      group_id: null,
      facility_id: null,
      field_id: null,
      circle_ids: null,
      user_console_id: null,
      reservation_type: null,
      reservation_date: null,
      start_time: null,
      start_date: null,
      end_date: null,
      reservation_time: 60,
      include_video: 'Y',
      price: null,
      players_qty: null,
      weekdays: [],
      phone_numbers: [],
      contact_name: null,
      repeat_n_weeks: 1,
      make_recurring: 'N',

      id: null,
      field_name: null,
      end_time: null,
      status: null,
      status_str: null,
      organizers: {},
      conflicts: {},
    })
    const postData = ref({
      name: null,
      note: null,
      playing_time: null,
      status: null,
    })
    const putData = ref({
      name: null,
      note: null,
      playing_time: null,
      status: null,
    })

    const typesOptions = computed(() => [
      { text: t('reservations.group_match'), value: 'group', color: 'group' },
      { text: t('reservations.pick_up_match'), value: 'pick_up', color: 'pickUp' },
      { text: t('reservations.regular_match'), value: 'regular', color: 'pickUp' },
      { text: t('reservations.academy'), value: 'academy', color: 'academy' },
      { text: t('reservations.other'), value: 'other', color: 'other' },
    ])

    // tabs
    // {
    //   title: 'General',
    //   icon: mdiAccountOutline,
    //   disabled: false,
    //   hide: false,
    // },
    const tabs = ref([
      {
        title: 'Bookings',
        icon: mdiCalendarCheckOutline,
        disabled: false,
        hide: false,
      },
    ])

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const goBack = () => {
      router.replace({
        name: 'views-booking-view',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    const cancelAction = () => {
      tab.value = 0
      reservationData.value = {
        group_id: null,
        facility_id: null,
        field_id: null,
        circle_ids: null,
        user_console_id: null,
        reservation_type: null,
        reservation_date: null,
        start_time: null,
        start_date: null,
        end_date: null,
        reservation_time: 60,
        include_video: 'Y',
        price: null,
        players_qty: null,
        weekdays: [],
        phone_numbers: [],
        contact_name: null,
        repeat_n_weeks: 1,
        make_recurring: 'N',

        id: null,
        field_name: null,
        end_time: null,
        status: null,
        status_str: null,
        organizers: {},
        conflicts: {},
      }
    }

    const onSave = async (data, checkConflicts) => {
      let resp = null
      let reservationBody = {}
      if (option.value === 1) {
        let title = ''
        let message = ''
        reservationBody = {
          group_id: configOrganization.value,
          facility_id: configFacility.value,
          field_id: data.field_id,
          circle_ids: data.circle_ids,
          user_console_id: userData.value.username,
          reservation_type: data.reservation_type,
          reservation_time: data.reservation_time,
          reservation_date: data.start_date,
          start_time: data.start_time,
          include_video: data.include_video,
          price: data.price,
          players_qty: data.players_qty,

          start_date: data.start_date,
          end_date: data.end_date,
          weekdays: data.weekdays,
          repeat_n_weeks: data.repeat_n_weeks,

          check_conflicts: checkConflicts || 'Y',
          phone_numbers: data.phone_numbers,
          contact_name: data.contact_name,
        }

        resp = await createRecurringReservation(reservationBody)

        if (!resp.ok) {
          title = resp.message.text
          message = resp.data.join(', ')
          Swal.fire({
            title,
            text: `${message}`,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si!',
            cancelButtonText: 'No',
          }).then(async result => {
            if (result.isConfirmed) {
              await onSave(data, 'N')
            }
          })
        }
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          name: data.name,
          playing_time: data.playing_time,
          note: data.note,
          status: data.status,
        })
        reservationBody = {
          start_date: data.start_date,
          end_date: data.end_date,
        }
        resp = await updateRecurringEndDate(data.id, reservationBody)
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        cancelAction()
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      if (!option.value) goBack()

      if (option.value && option.value !== 1) {
        reservationId.value = route.value.params.id
        reservationData.value = route.value.params.dataParams
        reservationData.value.make_recurring = 'Y'
        if (!reservationData.value) goBack()

        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      if (option.value === 1) {
        tabs.value.splice(1, 1)
      }
    })

    return {
      tab,
      tabs,
      option,
      reservationId,
      reservationData,
      postData,
      putData,
      configOrganization,
      configFacility,
      pageData,
      filterData,

      typesOptions,

      changeTab,
      onSave,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
