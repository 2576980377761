import { isEmpty } from './index'

export const required = value => {
  if (value !== null && value !== undefined) {
    if (typeof value === 'string' && value.length) return true
    if (typeof value === 'number' || typeof value === 'boolean') return true
    if (Array.isArray(value) && value.length) return true

    return 'This field is required'
  }

  return 'This field is required'

  // (value && value.length ? true : 'This field is required')
}

export const emailValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  /* eslint-disable no-useless-escape */
  // eslint-disable-next-line
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'The Email field must be a valid email'
}

export const macValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // eslint-disable-next-line
  const re = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/

  if (Array.isArray(value)) {
    return value.every(val => re.test(String(val)))
  }

  return re.test(String(value)) || 'The Mac Address field must be a valid Mac Address'
}

export const passwordValidator = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)

  return (
    // eslint-disable-next-line operator-linebreak
    validPassword ||
    'Field must contain at least one uppercase, lowercase, special character and digit with min 8 chars'
  )
}

export const confirmedValidator = (value, target) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  value === target || 'The Confirm Password field confirmation does not match'

export const between = (value, min, max) => () => {
  const valueAsNumber = Number(value)

  return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const numberValidator = value => {
  if (typeof value === 'number' && !Number.isNaN(value)) return true

  return `${value} is not a number`
}

export const integerValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^-?[0-9]+$/.test(String(val)))
  }

  return /^-?[0-9]+$/.test(String(value)) || 'This field must be an integer'
}

export const integerNoNegativeValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  if (Array.isArray(value)) {
    return value.every(val => /^[0-9]+$/.test(String(val)))
  }

  return /^[0-9]+$/.test(String(value)) || 'This field must be an integer positive'
}

export const regexValidator = (value, regex, message = 'The Regex field format is invalid') => {
  if (isEmpty(value)) {
    return true
  }

  let regeX = regex
  if (typeof regeX === 'string') {
    regeX = new RegExp(regeX)
  }

  if (Array.isArray(value)) {
    return value.every(val => regexValidator(val, { regeX }))
  }

  return regeX.test(String(value)) || message
}

export const alphaValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  // const valueAsString = String(value)

  return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

  return re.test(value) || 'URL is invalid'
}

export const lengthValidator = (value, length) => {
  if (isEmpty(value)) {
    return true
  }

  return value.length === length || `The Min Character field must be at least ${length} characters`
}

export const lengthBetweenValidator = (value, min, max) => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsNumber = Number(value.length)

  return (
    // eslint-disable-next-line operator-linebreak
    (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) ||
    `The field must be between ${min} and ${max} characters`
  )
}

export const alphaDashValidator = value => {
  if (isEmpty(value)) {
    return true
  }

  const valueAsString = String(value)

  return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}

export const validCreditCard = cardNumber => {
  // Accept only digits, dashes or spaces
  if (/[^0-9-\s]+/.test(cardNumber)) return false

  // The Luhn Algorithm. It's so pretty.
  let nCheck = 0
  let bEven = false
  let nDigit = null
  let cNumber = null

  // cardNumber = cardNumber.replace(/\D/g, '')
  cNumber = cardNumber.replace(/\D/g, '')

  for (let n = cNumber.length - 1; n >= 0; n -= 1) {
    const cDigit = cardNumber.charAt(n)
    nDigit = parseInt(cDigit, 10)

    // eslint-disable-next-line no-cond-assign
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9

    nCheck += nDigit
    bEven = !bEven
  }

  return nCheck % 10 === 0
}

export const minNumber = (value, min) => {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return 'Invalid Number'
  }

  if (value < min) {
    return `Number must be greater than or equal to ${min}`
  }

  return true
}

export const maxNumber = (value, max) => {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return 'Invalid Number'
  }

  if (value > max) {
    return `Number must be less than or equal to ${max}`
  }

  return true
}

export const charUnique = (s, c) => {
  let valid = true
  let str = []
  if (s) {
    if (typeof s === 'string') str = s.split('')
    else str = s.toString().split('')

    valid = str.filter(e => e === c)
  }

  return valid.length <= 1
}
