<template>
  <v-col
    cols="12"
    md="12"
    class="mt-4"
  >
    <span
      v-if="infoRequired"
      class="params red--text mb-3"
    >{{ t('required.label') }}</span>

    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="isModeSaveEdit && !noSave"
            color="#37a764"
            dark
            absolute
            small
            bottom
            right
            fab
            :class="{ 'btn-save-flat': isModeSaveEdit, 'btn-save-no-arrow': noArrow }"
            v-on="on"
            @click="onSave"
          >
            <v-icon>
              {{ icons.mdiContentSaveOutline }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.save") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="isModeSaveEdit"
            color="#e15b5b"
            dark
            absolute
            small
            bottom
            right
            fab
            :class="{ 'btn-save-flat': !isModeSaveEdit, 'btn-cancel-flat': isModeSaveEdit,'btn-cancel-no-arrow': noArrow, 'cancel-button-delete': noCancel, 'btn-cancel-no-save': noSave}"
            v-on="on"
            @click="onCancel"
          >
            <v-icon>
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.cancel") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!noArrow"
            color="#5b66e1"
            dark
            absolute
            small
            bottom
            right
            fab
            class="btn-arrow"
            v-on="on"
            @click="onNextTab"
          >
            <v-icon>
              {{ icons.mdiArrowRight }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.next_tab") }}</span>
      </v-tooltip>
    </v-fab-transition>
  </v-col>
</template>

<script>
import { mdiContentSaveOutline, mdiClose, mdiArrowRight } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'

export default {
  props: {
    tabNumber: {
      // next tab to go
      type: Number,
      default: 0,
    },

    // tells the component if user is in save and edit mode
    isModeSaveEdit: Boolean,

    // tells the component if user is going to save
    isModeSave: Boolean,

    // tells the component if user is onling watching an element
    isViewMode: Boolean,

    // hide arrow buttom
    noArrow: {
      type: Boolean,
      default: false,
    },

    // shows users there's required data to save
    infoRequired: {
      type: Boolean,
      default: false,
    },

    // hide cancel buttom
    noCancel: {
      type: Boolean,
      default: false,
    },

    // hide save buttom
    noSave: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    // i18n
    const { t } = useUtils()

    // these methods tell to parent component an action
    const onNextTab = () => {
      // go to next tab emit method
      emit('onNextTab', props.tabNumber + 1)
    }

    const onPreTab = () => {
      // go to previous tab emit method
      if (props.tabNumber !== 0) {
        emit('onPreTab', props.tabNumber - 1)
      }
    }

    const onSave = () => {
      // save emit method
      emit('onSave')
    }

    const onCancel = () => {
      // cancel emit method
      emit('onCancel')
    }

    return {
      // Methods
      onNextTab,
      onPreTab,
      onSave,
      onCancel,

      // i18n
      t,

      // Icons
      icons: {
        mdiContentSaveOutline,
        mdiClose,
        mdiArrowRight,
      },
    }
  },
}
</script>

<style>
.btn-cancel-flat {
  right: 114px !important;
  bottom: 10px !important;
}
.btn-save-flat {
  right: 65px !important;
  bottom: 10px !important;
}

.btn-save-no-arrow {
  right: 20px !important;
  bottom: 10px !important;
}

.btn-cancel-no-arrow {
  right: 70px !important;
  bottom: 10px !important;
}

.btn-cancel-no-save {
  right: 65px !important;
  bottom: 10px !important;
}

.btn-arrow {
  bottom: 10px !important;
}

.params {
  color: darkgrey;
  position: absolute;
  bottom: 0;
  left: 20px;
}

.cancel-button-delete {
  display: none !important;
}
</style>
